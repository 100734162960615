import axios from 'axios';

const api = axios.create({
baseURL: '/',
  timeout: 10000, // 请求超时设定
});

export default {
  searchDevice(mark) {
    return api.post('/api/ticket_query/', mark);
  },

  submitReportInfo(formData) {
    return api.post('/report/', formData);
  },

  downloadFile(fileName) {
    return api.get('/download/', {
      params: { file: fileName },
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // 提取文件名
      const contentDisposition = response.headers['content-disposition'];
      let downloadFileName = fileName; // 使用传递的 fileName 作为默认文件名
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          downloadFileName = fileNameMatch[1];
          console.log("download file: ", downloadFileName);
        }
      }

      link.setAttribute('download', downloadFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // 释放 URL 对象
    }).catch(error => {
      console.error('Download error:', error);
      throw error;
    });
  },
};