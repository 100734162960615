<template>
    <div class="min-h-screen p-6 flex flex-col items-center bg-gray-100">
      <div class="max-w-lg w-full mb-8">
        <h1 class="text-4xl font-bold mb-4 text-center text-emerald-700 dark:text-indigo-400">常见问题</h1>
        <div class="bg-white dark:bg-gray-800 shadow-lg rounded px-8 pt-6 pb-8 mb-4 border border-gray-300 text-left">
          <h2 class="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">目录</h2>
          <ul class="list-disc list-inside space-y-2 ">
            <li><a href="#" @click.prevent="scrollTo('q1')" class="text-emerald-600 hover:text-emerald-800 dark:text-indigo-400 dark:hover:text-indigo-500">如何查看系统版本？</a></li>
            <li><a href="#" @click.prevent="scrollTo('q2')" class="text-emerald-600 hover:text-emerald-800 dark:text-indigo-400 dark:hover:text-indigo-500">如何查看设备编号？</a></li>
            <li><a href="#" @click.prevent="scrollTo('q3')" class="text-emerald-600 hover:text-emerald-800 dark:text-indigo-400 dark:hover:text-indigo-500">如何查询工单状态？</a></li>
          </ul>
        </div>
      </div>
      <div class="max-w-lg w-full mb-8 text-left">
        <div class="bg-white dark:bg-gray-800 shadow-lg rounded px-8 pt-6 pb-8 mb-4 border border-gray-300">
            <ul class="space-y-6">
                <li id="q1">
                <h2 class="text-xl font-semibold text-gray-900 dark:text-white mb-2">1. 如何查看系统版本？</h2>
                <div class="pl-4">
                    <p class="text-gray-700 dark:text-gray-300 mb-1"><span class="font-bold">堡垒机</span>：登录管理员，选择左侧菜单【系统管理】-【系统升级】，复制【当前版本号】。</p>
                    <p class="text-gray-700 dark:text-gray-300 mb-1"><span class="font-bold">日志审计</span>：登录管理员，选择左侧菜单【系统配置】-【系统管理】-【系统升级】，复制【版本号】。</p>
                    <p class="text-gray-700 dark:text-gray-300"><span class="font-bold">数据库审计</span>：登录sysadmin查看版本号。</p>
                </div>
                </li>
                <li id="q2">
                <h2 class="text-xl font-semibold text-gray-900 dark:text-white mb-2">2. 如何查看设备编号？</h2>
                <div class="pl-4">
                    <p class="text-gray-700 dark:text-gray-300 mb-1"><span class="font-bold">堡垒机</span>：登录管理员，选择左侧菜单【系统管理】-【设备许可】，复制【设备编号】。</p>
                    <p class="text-gray-700 dark:text-gray-300 mb-1"><span class="font-bold">日志审计</span>：登录管理员，选择上方菜单【系统配置】-【系统管理】-【授权许可】，复制【设备序列】。</p>
                    <p class="text-gray-700 dark:text-gray-300"><span class="font-bold">数据库审计</span>：登录sysadmin查看设备编号。</p>
                </div>
                </li>
                <li id="q3">
                <h2 class="text-xl font-semibold text-gray-900 dark:text-white mb-2">3. 如何查询工单状态？</h2>
                <div class="pl-4">
                    <p class="text-gray-700 dark:text-gray-300">您可以通过工单提交页面下方【工单查询】链接进入工单查询页面，或<router-link to="/query" class="text-amber-500 hover:text-amber-600 dark:text-indigo-400 dark:hover:text-indigo-500 underline underline-offset-1">点击此处</router-link>直达。</p>
                </div>
                </li>
            </ul>
        </div>

        <div class="mt-4 flex justify-end">
          <router-link to="/" class="text-sm font-bold text-amber-500 hover:text-amber-600 dark:text-indigo-400 dark:hover:text-indigo-500 underline underline-offset-1">返回首页</router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'help_page',
    methods: {
      scrollTo(id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .help {
    text-align: center;
  }
  </style>
  