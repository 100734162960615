<template>
  <div class="min-h-screen p-6 flex flex-col items-center bg-gray-100">
    <div class="max-w-lg w-full mb-8 bg-white dark:bg-gray-800 shadow-lg rounded px-8 pt-6 pb-8 border border-gray-300 text-left">
      <span class="mb-1 text-gray-500 md:text-lg dark:text-gray-400 mr-5">工单编号</span>
      <span class="font-bold mr-3" :style="progressTextStyle" id="ticket_id">{{ local_ticket_info.ticket_id }}</span>
      <span style="display: inline-block;" class="mr-2"><button @click="copyToClipboard">
        <svg fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
          viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
        <g id="Text-files">
          <path d="M53.9791489,9.1429005H50.010849c-0.0826988,0-0.1562004,0.0283995-0.2331009,0.0469999V5.0228
            C49.7777481,2.253,47.4731483,0,44.6398468,0h-34.422596C7.3839517,0,5.0793519,2.253,5.0793519,5.0228v46.8432999
            c0,2.7697983,2.3045998,5.0228004,5.1378999,5.0228004h6.0367002v2.2678986C16.253952,61.8274002,18.4702511,64,21.1954517,64
            h32.783699c2.7252007,0,4.9414978-2.1725998,4.9414978-4.8432007V13.9861002
            C58.9206467,11.3155003,56.7043495,9.1429005,53.9791489,9.1429005z M7.1110516,51.8661003V5.0228
            c0-1.6487999,1.3938999-2.9909999,3.1062002-2.9909999h34.422596c1.7123032,0,3.1062012,1.3422,3.1062012,2.9909999v46.8432999
            c0,1.6487999-1.393898,2.9911003-3.1062012,2.9911003h-34.422596C8.5049515,54.8572006,7.1110516,53.5149002,7.1110516,51.8661003z
            M56.8888474,59.1567993c0,1.550602-1.3055,2.8115005-2.9096985,2.8115005h-32.783699
            c-1.6042004,0-2.9097996-1.2608986-2.9097996-2.8115005v-2.2678986h26.3541946
            c2.8333015,0,5.1379013-2.2530022,5.1379013-5.0228004V11.1275997c0.0769005,0.0186005,0.1504021,0.0469999,0.2331009,0.0469999
            h3.9682999c1.6041985,0,2.9096985,1.2609005,2.9096985,2.8115005V59.1567993z"/>
          <path d="M38.6031494,13.2063999H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0158005
            c0,0.5615997,0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4542999,1.0158997-1.0158997
            C39.6190491,13.6606998,39.16465,13.2063999,38.6031494,13.2063999z"/>
          <path d="M38.6031494,21.3334007H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0157986
            c0,0.5615005,0.4544001,1.0159016,1.0159006,1.0159016h22.3491974c0.5615005,0,1.0158997-0.454401,1.0158997-1.0159016
            C39.6190491,21.7877007,39.16465,21.3334007,38.6031494,21.3334007z"/>
          <path d="M38.6031494,29.4603004H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
            s0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4543991,1.0158997-1.0158997
            S39.16465,29.4603004,38.6031494,29.4603004z"/>
          <path d="M28.4444485,37.5872993H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
            s0.4544001,1.0158997,1.0159006,1.0158997h12.1904964c0.5615025,0,1.0158005-0.4543991,1.0158005-1.0158997
            S29.0059509,37.5872993,28.4444485,37.5872993z"/>
        </g>
        </svg>
        </button>
      </span>
      <span class=" text-green-500 md:text-lg dark:text-gray-400" id="copyResult"></span>
      <br>
      <span class="mb-1 text-gray-500 md:text-lg dark:text-gray-400 mr-5">工单状态</span>
      <span class="font-bold" :style="progressTextStyle">{{ local_ticket_info.progress }}</span>
      
    </div>


    <div class="max-w-lg w-full mb-8">
      <div class="bg-white dark:bg-gray-800 shadow-lg rounded px-8 pt-6 pb-8 mb-4 border border-gray-300 text-left">
        <dl class="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
          <div class="flex flex-col pb-3">
            <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400 text-xs">客户名称</dt>
            <dd class="text-lg">{{ local_ticket_info.client }}</dd>
          </div>
          <div class="flex flex-wrap pb-3">
            <div class="w-full md:w-3/5 pr-2">
              <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">设备编号</dt>
              <dd class="text-lg">{{ local_ticket_info.device_id }}</dd>
            </div>
            <div class="w-full md:w-2/5 pl-2">
              <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">设备类型</dt>
              <dd class="text-lg">{{ local_ticket_info.device_type }}</dd>
            </div>
          </div>
          <div class="flex flex-wrap pb-3">
            <div class="w-full md:w-3/5 pr-2">
              <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">系统版本</dt>
              <dd class="text-lg">{{ local_ticket_info.version }}</dd>
            </div>
            <div class="w-full md:w-2/5 pl-2">
              <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">区域</dt>
              <dd class="text-lg">{{ local_ticket_info.region }}</dd>
            </div>
          </div>
          <div class="flex flex-wrap pb-3">
            <div class="w-full md:w-3/5 pr-2">
              <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">联系人</dt>
              <dd class="text-lg">{{ local_ticket_info.name }}</dd>
            </div>
            <div class="w-full md:w-2/5 pl-2">
              <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">联系方式</dt>
              <dd class="text-lg">{{ local_ticket_info.contact }}</dd>
            </div>
          </div>
          <div class="flex flex-col pt-3">
            <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">问题描述</dt>
            <dd class="text-lg">
              <textarea 
                v-model="local_ticket_info.description" 
                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:bg-gray-100 disabled:dark:bg-gray-700 disabled:opacity-80 disabled:cursor-not-allowed"
                rows="5" 
                disabled>
              </textarea>
              </dd>
          </div>
        </dl>
      </div>
      <div class="mt-4 flex justify-end">
        <router-link to="/" class="text-sm font-bold text-amber-500 hover:text-amber-600 dark:text-indigo-400 dark:hover:text-indigo-500 underline underline-offset-1">返回首页</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket_info: {
      type: [Object, String], // 允许是对象或字符串类型
      required: true
    }
  },
  data() {
    return {
      local_ticket_info: {} // 本地数据属性
    };
  },
  computed: {
    // set progress text style
    progressTextStyle() {
      switch (this.local_ticket_info.progress) {
        case '队列中':
          return { color: '#f59e0b' }; // Amber color
        case '处理中':
          return { color: '#06b6d4' }; // Cyan color
        case '已完成':
          return { color: '#10b981' }; // Emerald color
        case '已关闭':
          return { color: '#000000' }; // Black color
        default:
          return { color: '#000000' }; // Default color
      }
    }
  },
  methods: {
    copyToClipboard() {
      // 获取元素和内容
      var el = document.getElementById("ticket_id");
      var content = el.innerText; // 使用 innerText 而不是 innerHTML
      var result_element = document.getElementById("copyResult");
      // 清空结果元素
      result_element.innerHTML = "";

      // 检查 navigator.clipboard 是否可用
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(content).then(function() {
          result_element.innerHTML = "[复制成功]";
        }).catch(function(err) {
          result_element.innerHTML = "[复制失败]" + err;
        });
      } else {
        // 使用 document.execCommand 作为备选方案
        var textarea = document.createElement("textarea");
        textarea.value = content;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          var successful = document.execCommand("copy");
          var msg = successful ? "[复制成功]" : "[复制失败]";
          result_element.innerHTML = msg;
        } catch (err) {
          result_element.innerHTML = "[复制失败]" + err;
        }
        document.body.removeChild(textarea);
      }
    }
  },
  created() {
    console.log("ticket detail:", this.ticket_info);
  },
  mounted() {
    if (typeof this.ticket_info === 'string') {
      this.local_ticket_info = JSON.parse(this.ticket_info);
    } else {
      this.local_ticket_info = this.ticket_info;
    }
  }
};
</script>

<style scoped>
.help {
  text-align: center;
}
</style>
