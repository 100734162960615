import { createRouter, createWebHistory } from 'vue-router';
import Help from '@/components/Help.vue';
import InitDeviceQuery from '@/components/InitDeviceQuery.vue';
import DeviceDetail from '@/components/DeviceDetail.vue';

const routes = [
  {
    path: '/ticket/',
    name: 'DeviceDetail',
    component: DeviceDetail,
    props: route => ({ ticket_info: route.query.ticket_info }),
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/',
    name: 'InitDeviceQuery',
    component: InitDeviceQuery,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Set the document title dynamically based on the route's meta title
router.beforeEach((to, from, next) => {
  document.title = 'LogBase 初始化进度查询'
  next();
});

export default router;

