<template>
  <div class="min-h-screen flex flex-col p-6 bg-gray-100">
    <h1 class="text-3xl font-bold mb-8 text-center text-gray-900">LogBase 初始化进度查询</h1>
    <div class="overflow-x-auto w-full">
      <table class="min-w-1/2 max-w-4xl mx-auto bg-white  shadow-lg rounded-lg border border-gray-300 text-left">
        <thead>
          <tr>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50 font-bold text-gray-900 " style="display:none">ID</th>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50 font-bold text-gray-900 ">标记</th>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50  font-bold text-gray-900 ">进度</th>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50  font-bold text-gray-900 ">同步空间</th>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50  font-bold text-gray-900 ">同步速度</th>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50  font-bold text-gray-900 ">剩余时间</th>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50  font-bold text-gray-900 ">上次更新</th>
            <th class="px-2 py-2 border-b border-gray-300 bg-gray-50  text-gray-900 ">
              <div class="flex items-center justify-center">
                <div class="flex items-center">
                  <input 
                    v-model="searchQuery"
                    @keyup.enter="searchDevice"
                    type="text"
                    id="search_input"
                    placeholder="请输入标记查询"
                    class="w-36 px-4 py-2 border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  dark:border-gray-600   mr-3 font-normal"
                  />
                  <button 
                    @click="searchDevice"
                    id="query_button" 
                    class="px-4 py-2 text-sm font-medium text-centerfont-medium text-white bg-slate-900 rounded-md shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-700 disabled:bg-slate-500 disabled:cursor-not-allowed disabled:opacity-90"
                  >
                    查询
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody id="ticket-tbody">
          <tr v-if="tickets.length === 0">
            <td colspan="6" class="px-2 py-2 text-center text-gray-500">暂无内容</td>
          </tr>
          <tr v-for="ticket in tickets" :key="ticket.id">
            <td class="px-2 py-2 border-b border-gray-300 text-sm text-gray-900 " style="display:none">{{ ticket.id }}</td>
            <td class="px-2 py-2 border-b border-gray-300 text-sm text-gray-900 ">{{ ticket.mark }}</td>
            <td class="px-2 py-2 border-b border-gray-300 text-sm text-gray-900 ">{{ ticket.resync_percentage }}%</td>
            <td class="px-2 py-2 border-b border-gray-300 text-sm text-gray-900 ">{{ ticket.synced_blocks }}/{{ ticket.total_blocks }}G</td>
            <td class="px-2 py-2 border-b border-gray-300 text-sm text-gray-900 ">{{ ticket.speed_mb_sec }}Mb/s</td>
            <td class="px-2 py-2 border-b border-gray-300 text-sm text-gray-900 ">{{ ticket.finish_time_min }}分钟</td>
            <td :data-value="ticket.last_update" class="px-2 py-2 border-b border-gray-300 text-sm text-gray-900">{{ formatDuration(ticket.last_update) }}前</td>
            <td class="px-2 py-2 border-b border-gray-300 text-sm text-blue-500">
              
              <button 
              type="button" 
              style="display:none"
              :id="'detailButton_' + ticket.id" 
              @click="detailTickets(ticket.id)" 
              class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300   ">查看详情</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '../api'; // 导入封装好的API模块

export default {
  data() {
    return {
      tickets: [],
      searchQuery: ''
    };
  },
  methods: {
    // search ticket
    async searchDevice() {
      var inputElement = document.getElementById('search_input');
      if (this.searchQuery.trim() === '') {
        inputElement.classList.add('border-red-500');
        return;
      } else {
        inputElement.classList.remove('border-red-500');
      }
      try {
        // get button
        var button_element = document.getElementById("query_button");
        button_element.disabled = true;
        button_element.innerHTML = `
<span>
  <span aria-hidden="true" role="status" class="inline-block rounded-full animate-spin">
  🧐
  </span>
  查询中...
</span>`
        var data = {
          ticket_mark: this.searchQuery
          }
        const response = await api.searchDevice(data); 
        button_element.disabled = false;
        button_element.innerHTML = '查询';
        if (response.data.result && response.data.data.length > 0) {
          this.searchQuery = '';
          this.tickets = response.data.data;  // 更新数据
        } else {
          console.log("data length: 0");
          this.tickets = [];
          alert('未查询到相关数据');
        }
      } catch (error) {
        button_element.disabled = false;
        button_element.innerHTML = '查询';
        console.error('Error querying ticket:', error);
        alert('查询失败，请稍后重试');
      }
    },
    formatDuration(seconds) {
      const days = Math.floor(seconds / (24 * 3600));
      seconds %= 24 * 3600;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;

      if (days > 0) return `${days}天`;
      if (hours > 0) return `${hours}小时`;
      if (minutes > 0) return `${minutes}分钟`;
      return `${seconds}秒`;
    }
  }
};
</script>

<style scoped>
/* Scoped styles for additional customization */
body {
  font-family: 'Inter', sans-serif;
  background-color: #f7fafc;
}
</style>
